import React from "react";
import cx from "clsx";
import { Checkbox } from "vapi-ui-common";
import styles from "./ImportByPartNumberCard.module.scss";

export interface ImportByPartNumberCardProps {
  title: string;
  partNumber: string;
  dealerCost: string;
  msrp: string;
  imageLink: string;
  isSelected: boolean;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const ImportByPartNumberCard = ({
  title,
  partNumber,
  dealerCost,
  msrp,
  imageLink,
  isSelected,
  onChange,
}: ImportByPartNumberCardProps) => {
  if (!title && !partNumber && !dealerCost && !msrp && !imageLink) {
    return null;
  }

  return (
    <div
      className={cx(styles.container, { [styles.selected]: isSelected })}
      data-testid="import-card-comp"
    >
      <Checkbox
        id="checkboxByPartNumber-1"
        className={cx(styles.cardCheckbox, {
          [styles.selectedCheckbox]: isSelected,
        })}
        defaultChecked={false}
        onChange={onChange}
        disabled={false}
        data-testid="checkbox-comp"
      />
      <img src={imageLink} alt="car part" className={styles.cardImage} />
      <div className={styles.textBlock}>
        <span className={styles.textTitle}>{title}</span>
        <span className={styles.textSubTitle}>{partNumber}</span>
        <span className={styles.description}>
          {`Dealer Cost: $${dealerCost}`}
        </span>
        <span className={styles.description}>
          {`Parts Only MSRP: $${msrp}`}
        </span>
      </div>
    </div>
  );
};

export default ImportByPartNumberCard;
