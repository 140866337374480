import React, { useCallback, useMemo, useState } from "react";
import {
  ActionBar,
  ActionBarFilterButton,
  ActionBarSection,
  Dropdown,
  DropdownContent,
  IconTextButton,
  Modal,
  ResetModal,
  SearchInput,
  useComponentVisible,
} from "vapi-ui-common";
import MenuDropdown from "../../../../components/menuModule/MenuDropdown";
import useStores from "../../../../stores/useStores";
import CommonLanguageFilter from "../CommonLanguageFilter/CommonLanguageFilter";
import ImportByPartNumberModal from "../../../../components/ImportByPartNumberModal";
import { AccessoryCommonLanguageItem } from "../../../../models/commonLanguage.model";

interface ActionBarCommonLanguageProps {
  searchText: string;
  productTypes: string[];
  productTypeFilters: string[];
  installPoints?: string[];
  installPointFilters?: string[];
  totalChanges?: number;
  setFilters: (
    productTypeFilters: string[],
    installPointFilers: string[]
  ) => void;
  onResetDraft?: () => void;
  onSearchChange: (value: string) => void;
  onCreateCommonLanguage?: (item?: AccessoryCommonLanguageItem) => void;
  readonly?: boolean;
}

const ActionBarCommonLanguage = ({
  searchText,
  onSearchChange,
  productTypes,
  productTypeFilters,
  installPoints,
  installPointFilters,
  totalChanges,
  setFilters,
  onResetDraft,
  onCreateCommonLanguage = () => undefined,
  readonly,
}: ActionBarCommonLanguageProps) => {
  const [openResetModal, setOpenResetModal] = useState(false);
  const { ref, isComponentVisible, setIsComponentVisible } =
    useComponentVisible(false);
  const [openImportModal, setOpenImportModal] = useState(false);
  const { teamStore } = useStores();

  const showImportADS =
    process.env.REACT_APP_IMPORT_ADS === "true" &&
    teamStore.team.allowImportADS;

  const handleImport = (item: AccessoryCommonLanguageItem) => {
    onCreateCommonLanguage(item);
    setOpenImportModal(false);
  };

  const addAccessoryOptList = useMemo(() => {
    const createCommonLangCTA = (
      <IconTextButton
        key="create_common_language"
        icon="link"
        text="Create Common Language"
      />
    );

    if (showImportADS) {
      return [
        <IconTextButton
          key="import_by_part_number"
          icon="plus"
          text="Import by Part Number"
        />,
        createCommonLangCTA,
      ];
    }

    return [createCommonLangCTA];
  }, [showImportADS]);

  const onSelect = useCallback(
    (value) => {
      switch (value) {
        case "create_common_language": {
          onCreateCommonLanguage();
          break;
        }
        case "import_by_part_number": {
          setOpenImportModal(true);
          break;
        }
        default:
          break;
      }
    },
    [onCreateCommonLanguage]
  );

  return (
    <ActionBar>
      <ActionBarSection>
        <SearchInput
          value={searchText}
          onSearch={(value) => onSearchChange(value)}
        />
        <Dropdown>
          <ActionBarFilterButton
            onClick={() => setIsComponentVisible(!isComponentVisible)}
          >
            Filter
          </ActionBarFilterButton>
          <DropdownContent ref={ref} open={isComponentVisible}>
            <CommonLanguageFilter
              productTypes={productTypes}
              productTypeFilters={productTypeFilters}
              installPoints={installPoints}
              installPointFilters={installPointFilters}
              onClose={() => setIsComponentVisible(false)}
              setFilters={setFilters}
            />
          </DropdownContent>
        </Dropdown>
        {!readonly && (
          <MenuDropdown
            menuButton={<IconTextButton icon="plus" text="Add Accessory" />}
            list={addAccessoryOptList}
            onSelect={onSelect}
          />
        )}
      </ActionBarSection>
      {!readonly && onResetDraft && (
        <ActionBarSection>
          <IconTextButton
            icon="undo"
            text="Reset Draft"
            onClick={() => setOpenResetModal(true)}
          />
          <Modal open={openResetModal} onClose={() => setOpenResetModal(false)}>
            <ResetModal
              changeLogLocation="/commonLanguage/changeLog"
              close={() => setOpenResetModal(false)}
              pageName="Common Language"
              updateCount={totalChanges ?? 0}
              resetDraft={onResetDraft}
            />
          </Modal>
        </ActionBarSection>
      )}
      <ImportByPartNumberModal
        openImportModal={openImportModal}
        setOpenImportModal={setOpenImportModal}
        isCommonLanguageDraft
        onClickInCommonDraft={handleImport}
      />
    </ActionBar>
  );
};
export default ActionBarCommonLanguage;
