import React, { useEffect, useState } from "react";
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  SearchInput,
} from "vapi-ui-common";
import ImportByPartNumberCard from "./ImportByPartNumberCard";
import AccessoryItemVM from "../../models/accessories/AccessoryItemVM";
import { AccessoryCommonLanguageItem } from "../../models/commonLanguage.model";
import styles from "./ImportByPartNumberModal.module.scss";

export interface ImportByPartNumberModalProps {
  openImportModal: boolean;
  setOpenImportModal: (val: boolean) => void;
  isCommonLanguageDraft?: boolean;
  onClickInCommonDraft?: (item: AccessoryCommonLanguageItem) => void;
  onClickInAccessoryDraft?: (item: AccessoryItemVM) => void;
}

const ImportByPartNumberModal = ({
  openImportModal,
  setOpenImportModal,
  isCommonLanguageDraft,
  onClickInCommonDraft,
  onClickInAccessoryDraft,
}: ImportByPartNumberModalProps) => {
  const [searchText, setSearchText] = useState<string>("");
  const [isSelected, setIsSelected] = useState<boolean>(false);
  const [accessoryItem, setAccessoryItem] = useState<AccessoryItemVM | null>(
    null
  );
  const [commonLanguageItem, setCommonLanguageItem] =
    useState<AccessoryCommonLanguageItem | null>(null);

  useEffect(() => {
    if (searchText.length === 14) {
      if (isCommonLanguageDraft) {
        setAccessoryItem(null);
        // code to fetch part as an AccessoryItemVM
      } else {
        setCommonLanguageItem(null);
        // code to fetch part as an AccessoryItemVM
      }
    }
  }, [searchText]);

  const onSearch = (val: string) => {
    setSearchText(val);
  };

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsSelected(e.currentTarget.checked);
  };

  const onClick = () => {
    if (isCommonLanguageDraft && commonLanguageItem && onClickInCommonDraft) {
      onClickInCommonDraft(commonLanguageItem);
    } else if (accessoryItem && onClickInAccessoryDraft) {
      onClickInAccessoryDraft(accessoryItem);
    }
  };

  return (
    <div data-testid="import-modal-comp">
      <Modal open={openImportModal} onClose={() => setOpenImportModal(false)}>
        <ModalHeader onClose={() => setOpenImportModal(false)}>
          Import by Part Number
        </ModalHeader>
        <ModalBody className={styles.modalBody}>
          <SearchInput
            className={styles.searchInput}
            placeholder="Search by Part Number"
            value={searchText}
            onSearch={onSearch}
            maxLength={14}
          />
          <ImportByPartNumberCard
            key="123"
            title={
              isCommonLanguageDraft
                ? commonLanguageItem?.title ?? ""
                : accessoryItem?.title ?? "1"
            }
            partNumber={
              isCommonLanguageDraft
                ? commonLanguageItem?.partNumber ?? ""
                : accessoryItem?.partNumber ?? "1"
            }
            dealerCost={
              isCommonLanguageDraft
                ? commonLanguageItem?.partsOnlyDealerCost ?? ""
                : accessoryItem?.partsOnlyDealerCost ?? "1"
            }
            msrp={
              isCommonLanguageDraft
                ? commonLanguageItem?.partsOnlyMSRP ?? ""
                : accessoryItem?.partsOnlyMSRP ?? "1"
            }
            imageLink={
              isCommonLanguageDraft
                ? commonLanguageItem?.images?.[0]?.image ?? ""
                : accessoryItem?.images?.[0]?.image ?? ""
            }
            isSelected={isSelected}
            onChange={onChange}
          />
        </ModalBody>
        <ModalFooter className={styles.modalFooter}>
          <Button
            variant="transparent"
            onClick={() => setOpenImportModal(false)}
          >
            Cancel
          </Button>
          <Button variant="primary" onClick={onClick} disabled={!isSelected}>
            Import by Part Number
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default ImportByPartNumberModal;
