import React from "react";
import { Icon, Status, StatusComponent } from "vapi-ui-common";
import { SeriesItem, SeriesMyItem } from "../../../gql/generated";
import useStores from "../../../stores/useStores";

interface OutOfSyncStatusModelProps {
  model: SeriesMyItem;
  seriesItem: SeriesItem;
}

const OutOfSyncStatusModel = ({
  model,
  seriesItem,
}: OutOfSyncStatusModelProps) => {
  const { teamStore } = useStores();

  const { outOfSync, spanishVersion, comLangIds, year, isDraft } = model;

  if (outOfSync && spanishVersion) {
    return (
      <StatusComponent
        statusText="Out of Sync"
        status={Status.OUT_OF_SYNC}
        statusIcon={Icon.EXCLAMATION_TRIANGLE}
      />
    );
  }

  const showSyncAmsAds =
    teamStore.team.allowSyncAmsAds &&
    process.env.REACT_APP_SYNC_AMS_ADS === "true";

  const statusText: string[] = [];
  let hasChanges = false;

  // TODO: Fix condition on wireup
  const randomBoolean = Math.random() < 0.5;
  if (showSyncAmsAds && randomBoolean) {
    statusText.push("AMS");
    hasChanges = true;
  }

  const showCommonLanguage =
    teamStore.team.showCommonLanguage &&
    process.env.REACT_APP_COMMON_LANGUAGE === "true";

  if (showCommonLanguage && comLangIds?.length) {
    statusText.push("Common Language");
    hasChanges = true;
  }

  if (hasChanges) {
    const hasDraft = seriesItem.years?.find(
      (my) => my.year === year && my.isDraft
    );

    if (!isDraft && hasDraft) return null;
    const sText = `${statusText.join(" and ")} out of sync`;

    return (
      <StatusComponent
        statusText={sText}
        status={Status.OUT_OF_SYNC}
        statusIcon={Icon.EXCLAMATION_TRIANGLE}
      />
    );
  }

  return null;
};

export default OutOfSyncStatusModel;
