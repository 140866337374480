/* eslint-disable no-param-reassign */
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import cx from "clsx";
import { observer } from "mobx-react-lite";
import React from "react";
import { Button, TableCell, TableRow } from "vapi-ui-common";
import AccessoryRichText from "../../../../components/AccessoryRichText";
import StatusIndicator from "../../../../components/StatusIndicator/StatusIndicator";
import ContextMenuCell from "../../../../components/tableCells/tableCells/ContextMenuCell/ContextMenuCell";
import { AccessoryCommonLanguageItem } from "../../../../models/commonLanguage.model";
import useStores from "../../../../stores/useStores";
import useRowHandlers from "../../hooks/useRowHandlers";
import styles from "./commonLanguageTable.module.scss";

interface CLLeftRowProps {
  item: AccessoryCommonLanguageItem;
  readonly?: boolean;
}

const CLLeftRow = ({ item, readonly }: CLLeftRowProps) => {
  const { handleRichTextChange, copyItem, deleteItem } = useRowHandlers(item);
  const { teamStore } = useStores();

  // TODO: Fix condition on wireup
  const randomBoolean = Math.random() < 0.5;
  const showSyncAmsAds =
    teamStore.team.allowSyncAmsAds &&
    process.env.REACT_APP_SYNC_AMS_ADS === "true" &&
    !readonly &&
    randomBoolean;

  return (
    <TableRow>
      {!readonly && (
        <TableCell className={cx(styles.cell, styles.actionButtons)}>
          <ContextMenuCell
            disabled={readonly}
            description={item.title}
            itemType="Common Language"
            deleteItem={deleteItem}
            copyItem={copyItem}
            deleteItemText={item.publishedDate ? "Archive" : "Delete"}
          />
        </TableCell>
      )}

      {/* Accessory Name */}
      <TableCell
        className={cx(styles.cell, styles.textArea, {
          [styles.syncAmsAdsCell]: showSyncAmsAds,
        })}
      >
        {item.isArchived && <StatusIndicator>Archived</StatusIndicator>}
        <AccessoryRichText
          value={item.title}
          onBlur={handleRichTextChange("title")}
          disabled={readonly}
          required={!item.title}
        />

        {showSyncAmsAds && (
          <Button
            className={cx(styles.syncAmsAdsBtn, {
              [styles.hasUpdatesBtn]: true,
            })}
          >
            <FontAwesomeIcon
              className={styles.warningIcon}
              icon={faExclamationTriangle}
            />
            <span>AMS</span>
          </Button>
        )}
      </TableCell>

      {/* Copy */}
      <TableCell className={cx(styles.cell, styles.textArea, styles.wide)}>
        <AccessoryRichText
          value={item.copy}
          onBlur={handleRichTextChange("copy")}
          disabled={readonly}
          required={!item.copy}
        />
      </TableCell>
    </TableRow>
  );
};

export default observer(CLLeftRow);
